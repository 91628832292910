import React, { Fragment, Suspense, lazy } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import '../styles/main.css'
import Header from './Header'
import Footer from './Footer'

const Home = lazy(() => import('../pages/Home'))
const Characters = lazy(() => import('../pages/Characters'))
const Character = lazy(() => import('../pages/Character'))

function AppRouter () {
  return (
    <>
    <div className='min-h-screen flex flex-col bg-gradient-to-br from-slate-800 to-slate-900 text-slate-300'>
      <Header />
        <div className='flex flex-col items-center flex-1'>
          <Router>
            <Routes>
              <Route path="/" element={
                <Suspense fallback={<>Loading...</>}>
                  <Home />
                </Suspense>
              } />
              <Route path="/personnages" element={
                <Suspense fallback={<>Loading...</>}>
                  <Characters />
                </Suspense>
              } />
              <Route path="/personnages/:characterUrl" element={
                <Suspense fallback={<>Loading...</>}>
                  <Character />
                </Suspense>
              } />
            </Routes>
          </Router>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default AppRouter

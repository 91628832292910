import React, { useEffect, useState } from 'react'

export default function Header () {
  const [scrolling, setScrolling] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolling(true)
      } else {
        setScrolling(false)
      }
    }

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <div className="h-40 sticky left-0 top-0 right-0 flex justify-center z-30 text-dark w-full">
      <div className={`${scrolling ? 'max-w-[100%] top-0 translate-y-0' : 'max-w-screen-xl top-1/2 -translate-y-1/2 px-10 py-5 rounded-full shadow-[inset_0_-25px_50px_-20px_#B45309,_0_2.5px_10px_#B4530933]'} bg-amber-100 w-full transition-all duration-500 h-fit absolute text-slate-900`}>Header</div>
    </div>
  )
}

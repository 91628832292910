import Airtable from 'airtable'

const baseId = 'appxwvjc0FhwO4Iqk'

const store = {
  getBaseContent: function (tableName) {
    return new Promise((resolve, reject) => {
      const base = new Airtable({ apiKey: process.env.REACT_APP_READ_ACCESS }).base(baseId)
      const table = base(tableName)
      const characters = []

      table.select().eachPage((records, fetchNextPage) => {
        records.forEach(record => characters.push(record.fields))
        fetchNextPage()
      }, err => {
        if (err) {
          console.error('Erreur lors de la récupération des enregistrements :', err)
          reject(err)
        } else {
          resolve(characters)
        }
      })
    })
  },

  fetchBy: async function (tableName, columnName, target) {
    const base = new Airtable({ apiKey: process.env.REACT_APP_READ_ACCESS }).base(baseId)
    const table = base(tableName)

    console.log(columnName + ' -> ' + target)

    return new Promise((resolve, reject) => {
      table.select({ filterByFormula: `${columnName} = "${target}"` }).firstPage((err, record) => {
        if (err) {
          console.error(`Erreur lors de la récupération de la target '${target}' dans la table '${tableName}'`, err)
          reject(err)
        } else {
          resolve(record[0].fields)
        }
      })
    })
  }
}

export default store
